import { JobFunctions, Industry } from "."

export const jobFunctionsENJPMapper: { [key in JobFunctions]: string } = {
  "Accounting / Auditing": "会計・経理",
  Administrative: "一般職",
  Advertising: "広告・宣伝",
  Analyst: "データ分析",
  "Art / Creative": "芸術・アート",
  "Business Development": "事業開発",
  Consulting: "コンサルティング",
  "Customer Service": "カスタマーサービス",
  Design: "デザイン・設計",
  Distribution: "物流",
  Education: "教育",
  Engineering: "エンジニアリング",
  Finance: "金融",
  "General Business": "総務",
  "Health Care Provider": "医療・福祉",
  "Human Resources": "人事・採用",
  "Information Technology": "IT・コンピューター",
  Legal: "法律/法務",
  Management: "総合職",
  Manufacturing: "製造",
  Marketing: "マーケティング",
  Other: "その他",
  "Product Management": "プロダクトマネージャー",
  Production: "プロダクション",
  "Project Management": "プロジェクトマネージャー",
  "Public Relations": "広報",
  Purchasing: "購買",
  "Quality Assurance": "品質管理",
  Research: "研究開発",
  Sales: "営業",
  Science: "サイエンス",
  "Strategy / Planning": "企画開発",
  "Supply Chain": "サプライチェーン",
  Training: "研修・トレーニング",
  "Writing / Editing": "出版・編集",
}

export const industryENJPMapper: { [key in Industry]: string } = {
  Accounting: "会計・税務",
  Administration: "行政",
  "Airlines/Aviation": "航空",
  "Alternative Dispute Resolution": "示談・調停",
  "Alternative Medicine": "代替医療",
  Animation: "アニメーション",
  "Apparel & Fashion": "ファッション・アパレル",
  "Architecture & Planning": "設計 & プラン",
  "Arts and Crafts": "アート・工芸",
  Automotive: "自動車",
  "Aviation & Aerospace": "航空・宇宙",
  Banking: "銀行",
  Biotechnology: "バイオテクノロジー",
  "Broadcast Media": "放送",
  "Building Materials": "建築資材",
  "Business Supplies and Equipment": "事務用品・機材",
  "Capital Markets": "キャピタルマーケット",
  Chemicals: "化学",
  "Civic & Social Organization": "市民・公共機関",
  "Civil Engineering": "土木工事",
  "Commercial Real Estate": "商業不動産",
  "Computer & Network Security": "コンピューター & ネットワークセキュリティ",
  "Computer Games": "コンピューターゲーム",
  "Computer Hardware": "コンピューターハードウェア",
  "Computer Networking": "ネットワーク機器",
  "Computer Software": "ソフトウェア",
  Construction: "建設・土木",
  "Consumer Electronics": "総合電機・家電",
  "Consumer Goods": "消費財",
  "Consumer Services": "消費者サービス",
  Cosmetics: "化粧品・美容",
  Dairy: "酪農業",
  "Defense & Space": "防衛・宇宙",
  Design: "デザイン",
  "Education Management": "教育管理",
  "E-Learning": "E-ラーニング",
  "Electrical/Electronic Manufacturing": "電気・電子製品",
  Entertainment: "エンターテイメント",
  "Environmental Services": "環境サービス",
  "Events Services": "イベントサービス",
  "Executive Office": "ちらし/郵送",
  "Facilities Services": "ファシリティ",
  Farming: "農業",
  "Financial Services": "金融サービス",
  "Fine Art": "美術・芸術",
  Fishery: "水産・水産加工業",
  "Food & Beverages": "食品 & 飲料",
  "Food Production": "食品メーカー",
  "Fund-Raising": "資金調達",
  Furniture: "家具",
  "Gambling & Casinos": "ギャンブル・カジノ",
  Glass: "ガラス、セラミックス、コンクリート",
  "Government Administration": "官公庁",
  "Government Relations": "政府",
  "Graphic Design": "グラフィックデザイン",
  Health: "ヘルス・フィットネス",
  "Higher Education": "高等教育",
  "Hospital & Health Care": "病院 & 医療機関",
  Hospitality: "ホテル",
  "Human Resources": "人事",
  "Import and Export": "貿易",
  "Individual & Family Services": "社会福祉サービス",
  "Industrial Automation": "産業機器",
  "Information Services": "情報サービス",
  "Information Technology and Services": "IT ・情報サービス",
  Insurance: "保険",
  "International Affairs": "外務",
  "International Trade and Development": "国際貿易・海外開発",
  Internet: "インターネット",
  "Investment Banking": "投資銀行",
  "Investment Management": "投信投資顧問",
  Judiciary: "司法",
  "Law Enforcement": "警察",
  "Law Practice": "弁護士",
  "Legal Services": "公証人・司法書士",
  "Legislative Office": "立法",
  Leisure: "レジャー・旅行",
  Libraries: "図書館",
  "Logistics and Supply Chain": "物流・供給",
  "Luxury Goods & Jewelry": "高級品・貴金属",
  Machinery: "機械",
  "Management Consulting": "コンサルティング",
  Maritime: "海運",
  "Market Research": "市場調査",
  "Marketing and Advertising": "マーケティング・広告",
  "Mechanical or Industrial Engineering": "機械・産業エンジニアリング",
  "Media Production": "メディア制作",
  "Medical Devices": "医療機器",
  "Medical Practice": "医療機関",
  "Mental Health Care": "メンタルケア",
  Military: "軍事",
  "Mining & Metals": "鉱業・金属",
  "Motion Pictures and Film": "映像・映画",
  "Museums and Institutions": "博物館・美術館",
  Music: "音楽・音響",
  Nanotechnology: "ナノテクノロジー",
  Newspapers: "新聞",
  "Nonprofit Organization Management": "非営利団体",
  "Oil & Energy": "石油・エネルギー",
  "Online Media": "オンラインメディア",
  Other: "その他",
  "Outsourcing/Offshoring": "業務請負",
  "Package/Freight Delivery": "貨物・宅配配送",
  "Packaging and Containers": "包装・容器製造業",
  "Paper & Forest Products": "紙・パルプ・紙加工品",
  "Performing Arts": "公演芸術",
  Pharmaceuticals: "製薬",
  Philanthropy: "チャリティ",
  Photography: "写真",
  Plastics: "プラスチック",
  "Political Organization": "政治組織",
  "Primary/Secondary Education": "初等/中等教育",
  Printing: "印刷",
  "Professional Training & Coaching": "教育・学習支援業",
  "Program Development": "プログラム開発",
  "Public Policy": "公共政策",
  "Public Relations and Communications": "広報",
  "Public Safety": "公安",
  Publishing: "出版",
  Radio: "ラジオ",
  "Railroad Manufacture": "鉄道業",
  Ranching: "畜産業",
  "Real Estate": "不動産",
  "Recreational Facilities and Services": "レジャー施設・サービス",
  "Religious Institutions": "宗教",
  "Renewables & Environment": "グリーンエネルギー・リサイクル",
  Research: "研究開発",
  Restaurants: "飲食・レストラン",
  Retail: "小売",
  "Security and Investigations": "セキュリティ・調査",
  Semiconductors: "半導体",
  Shipbuilding: "造船",
  "Sporting Goods": "スポーツ用品",
  Sports: "スポーツ",
  "Staffing and Recruiting": "人材",
  Supermarkets: "百貨店・スーパー",
  Telecommunications: "通信業",
  Textiles: "繊維",
  "Think Tanks": "シンクタンク",
  Tobacco: "タバコ製造業",
  "Translation and Localization": "翻訳・ローカライゼーション",
  "Transportation/Trucking/Railroad": "運輸 （陸送、鉄道）",
  Utilities: "電力・ガス",
  "Venture Capital & Private Equity": "ベンチャーキャピタル",
  Veterinary: "獣医",
  Warehousing: "倉庫",
  Wholesale: "卸売",
  "Wine and Spirits": "アルコール・酒造",
  Wireless: "ワイヤレス",
  "Writing and Editing": "著述・編集",
}
