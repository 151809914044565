import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { remarkForm, DeleteAction } from "gatsby-tinacms-remark"
import { LinkedInCard, TheTeamCardDetail } from "../components/atoms/Cards"
import {
  seniority,
  jobFunctions,
  industry,
  filterTypeOptions,
  JobFunctions,
  Industry,
} from "../constants"
import { CallToActionJobPost } from "../components/CallToAction"
import { Languages } from "../provider"
import { useLang } from "../components/Header/LanguageToggle/useLang"
import { NoteField } from "../templateComponents/NoteComponent"
import { useIsMobile } from "../components/hooks/useDimensions"
import { navigateToPrevOrDefault } from "../utils"
import { EmailType } from "../utils/sendEmail"
import { Button } from "../components/atoms/Button"
import {
  jobFunctionsENJPMapper,
  industryENJPMapper,
} from "../constants/mappers"

interface Props {
  data: any
  pageContext: any
  location: Location
}

interface JobFunction {
  title: JobFunctions
  id: string
}

const translateJobFunctions = (jobFunctions: JobFunction[]) =>
  jobFunctions.map(jobFunction => {
    const jpTitle = jobFunctionsENJPMapper[jobFunction.title]

    if (jpTitle) return { ...jobFunction, title: jpTitle }
    return jobFunction
  })

interface Industries {
  title: Industry
  id: string
}

const translateIndustry = (industries: Industries[]) =>
  industries.map(industry => {
    const jpTitle = industryENJPMapper[industry.title]

    if (jpTitle) return { ...industry, title: jpTitle }
    return industry
  })

const JobPostTemplate: React.FC<Props> = props => {
  const lang = useLang()
  const isMobile = useIsMobile()
  const post = props.data.markdownRemark
  const consultant = post.frontmatter.consultant
  const consultantMarkDown = props.data.allMarkdownRemark.edges.filter(
    (edge: any) => edge.node.frontmatter.consultant === consultant
  )
  const consultantData =
    consultantMarkDown.length && consultantMarkDown[0].node.frontmatter
  const img = consultantData?.image?.childImageSharp.fluid || ""

  const toggleMargin = props.data.markdownRemark.frontmatter.hasSubtitle

  const siteTitle = props.data.site.siteMetadata.title
  const { previous, next } = props.pageContext

  const jobFunctions =
    lang === Languages.EN
      ? post.frontmatter.jobFunction
      : translateJobFunctions(post.frontmatter.jobFunction)

  const industry =
    lang === Languages.EN
      ? post.frontmatter.industry
      : translateIndustry(post.frontmatter.industry)

  const handleNav = () => navigateToPrevOrDefault("/about")

  return (
    <Layout location={props.location}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <div className="w-screen">
        <article className="max-w-6xl p-10 mx-auto">
          <h1 className="max-w-lg text-3xl font-black leading-none tracking-tight sm:pl-6 text-vp-blue">
            {post.frontmatter.title}
          </h1>

          <div className="flex flex-col items-start content-between mt-4 sm:flex-row">
            <div className="sm:w-8/12 sm:px-6 sm:mr-6">
              <section
                className="text-base leading-tight text-gray-800 override-markdown-headings override-list-style override-paragraph"
                dangerouslySetInnerHTML={{ __html: post.html }}
              />
            </div>

            <div
              className="w-full sm:w-4/12"
              style={{ marginTop: toggleMargin ? "4.5rem" : "" }}
            >
              {!isMobile && (
                <>
                  <LinkedInCard
                    location={post.frontmatter.location}
                    jobFunction={jobFunctions}
                    seniority={post.frontmatter.seniority}
                    industry={industry}
                    additionalClassName=""
                  />
                  <br className="h-2" />
                </>
              )}
              <CallToActionJobPost
                title="Interested?"
                text={
                  lang === Languages.EN
                    ? "Enter your details and we will get in touch soon."
                    : "お問合せ内容のご記入をお願いします。"
                }
                jobPost={post.frontmatter}
                url={props.location.href}
                type={EmailType.jobPost}
              />

              {!isMobile && (
                <>
                  <br className="h-2" />
                  <TheTeamCardDetail
                    fadeIn
                    name={props.data.markdownRemark.frontmatter.consultant}
                    role={`${consultantData.position}, ${consultantData.location}`}
                    img={img}
                    slug={props.data}
                    linkedIn={consultantData.linkedIn}
                    email={consultantData.email}
                  />
                </>
              )}
            </div>
          </div>
          <nav className="pt-20 pb-16 pl-4 sm:pt-32 sm:pl-0">
            <Button text="BACK" onClick={handleNav} />
          </nav>
        </article>
      </div>
    </Layout>
  )
}

/**
 * This object defines the form for editing blog post.
 */
const JobPostForm = {
  label: "Edit Job Post",
  actions: [DeleteAction],
  /**
   * The list of fields tell us what the form looks like.
   */
  fields: [
    {
      //
      name: "frontmatter.title",
      component: "text",
      label: "Job Title",
      required: true,
    },
    {
      name: "frontmatter.description",
      component: "textarea",
      label: "Summary",
      description: "this will show on the Recent Positions Section.",
    },
    {
      name: "frontmatter.date",
      component: "date",
      label: "Date Posted",
      description: "changing the date will change the order of job postings.",
    },
    {
      name: "frontmatter.consultant",
      component: "text",
      label: "Consultant in Charge",
      description:
        "must match a consultant already in the system to show consultant data.",
    },
    {
      name: "frontmatter.filterType",
      component: "select",
      label: "Job Filter Type",
      description: "this will determine how the user filters for jobs.",
      options: filterTypeOptions,
      defaultValue: "Consulting Firms",
    },
    {
      name: "frontmatter.seniority",
      component: "select",
      label: "Seniority Level",
      options: seniority,
    },
    {
      name: "frontmatter.jobFunction",
      component: "group-list",
      label: "Job Functions",
      description: "Please select max 3",
      itemProps: (item: any) => ({
        key: item.id,
        label: item.title,
      }),
      defaultItem: () => ({
        title: "Information Technology",
        id: Math.random()
          .toString(36)
          .substr(2, 9),
      }),
      fields: [
        {
          name: "title",
          label: "Job Function",
          component: "select",
          options: jobFunctions,
        },
      ],
    },
    {
      name: "frontmatter.industry",
      component: "group-list",
      label: "Industry",
      description: "Please select max 3",
      itemProps: (item: any) => ({
        key: item.id,
        label: item.title,
      }),
      defaultItem: () => ({
        title: "Information Technology and Services",
        id: Math.random()
          .toString(36)
          .substr(2, 9),
      }),
      fields: [
        {
          name: "title",
          label: "Industry",
          component: "select",
          options: industry,
        },
      ],
    },
    {
      name: "frontmatter.location",
      component: "text",
      label: "Location",
      description:
        "if US, input 'City, State'. if Others, input 'City, Country'",
      defaultValue: "Tokyo, Japan",
    },
    {
      name: "frontmatter.hasSubtitle",
      component: "toggle",
      label: "Toggle Sidebar Height",
      description:
        "toggle to align with body text if you have a subtitle (e.g. Company description)",
    },
    {
      name: "frontmatter.lang",
      component: "select",
      label: "Language",
      options: [Languages.EN, Languages.JP],
      defaultValue: Languages.EN,
      description: "This Job Post wil only be shown on the language selected",
    },
    {
      name: "rawMarkdownBody",
      component: "markdown",
      label: "Job Description",
      description:
        "use 'Heading' to create subtitles. all headings have been set to the same size",
    },
    {
      name: "warning",
      component: NoteField,
    },
  ],
}

/**
 * The `remarkForm` higher order component wraps the `BlogPostTemplate`
 * and generates a new form from the data in the `markdownRemark` query.
 */
export default remarkForm(JobPostTemplate, JobPostForm)

// https://www.christopherbiscardi.com/post/accessing-frontmatter-through-interfaces/
export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      ...TinaRemark
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        jobFunction {
          title
        }
        location
        seniority
        industry {
          title
        }
        hasSubtitle
        lang
        consultant
      }
    }
    allMarkdownRemark(
      filter: { fileRelativePath: { regex: "/content/consultants/" } }
    ) {
      edges {
        node {
          frontmatter {
            consultant
            location
            linkedIn
            email
            position
            image {
              childImageSharp {
                fluid(quality: 100, maxWidth: 800) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
